<template>
     <div class="subAccount">
          <div class="title">
               {{L['子账号管理']}}
          </div>
          <div class="sub_acc_con">
               <div class="box3 flex-row">
                    <span class="word25">{{L['启用状态:']}}</span>
                    <div class="main2 flex-col">
                         <div class="section22 flex-row">
                              <el-select v-model="ableState" :placeholder="L['请选择启用状态']" @change="stateChange">
                                   <el-option v-for="item in options" :key="item.value" :label="item.label"
                                        :value="item.state">
                                   </el-option>
                              </el-select>
                         </div>
                    </div>
                    <div class="main3 flex-col">
                         <div class="bd8 flex-row">
                              <input type="text" class="info16" :placeholder="L['名称/用户名/手机号']" v-model="accountSe">
                              <div class="box4 flex_row_center_center">
                                   <span class="iconfont iconsousuo-" @click="search('go')"></span>
                              </div>

                         </div>
                    </div>
                    <div class="main4 flex_row_center_center" @click="search('reset')">
                         <span class="word26">{{L['重']}}&nbsp;{{L['置']}}</span>
                    </div>
               </div>
               <div class="box5 flex-row">
                    <button class="bd9 flex-col" @click="batchDel">
                         <span class="info17">{{L['批量删除']}}</span>
                    </button>
                    <div class="bd10 flex-col">
                         <span class="word27" @click="toCreate">{{L['创建子账号']}}</span>
                    </div>
               </div>
               <div class="sub_table">
                    <el-table ref="multipleTable" :data="subAcList" style="width: 100%"
                         @selection-change="handleSelectionChange">
                         <el-table-column type="selection" width="45" />
                         <el-table-column property="designerName" :label="L['名称']" width="160" :formatter="formatter" />
                         <el-table-column property="memberName" :label="L['用户名']" width="180" :formatter="formatter" />
                         <el-table-column property="memberMobile" :label="L['手机号']" width="180" :formatter="formatter" />
                         <el-table-column :label="L['开启']" width="140">
                              <template #default="scope">
                                   <div @click="switchOp(scope.row)" style="display: inline;">
                                        <el-switch v-if="isReady" v-model="scope.row.state" :active-value="1"
                                             :inactive-value="0"></el-switch>
                                   </div>
                              </template>
                         </el-table-column>
                         <el-table-column :label="L['操作']">
                              <template #default="scope">
                                   <div class="options">
                                        <span class="txt17" @click="view('order', scope.row)">{{L['查看订单']}}</span>
                                        <span class="txt17" @click="view('reset', scope.row)">{{L['重置密码']}}</span>
                                        <span class="txt17" @click="view('edit', scope.row)">{{L['编辑']}}</span>
                                        <span class="txt17" @click="delAcc(scope.row.memberId)">{{L['删除']}}</span>
                                   </div>
                              </template>
                         </el-table-column>
                    </el-table>
               </div>
          </div>

          <subAcDialog ref="dialog" :memberId="selMeId" @refresh="refresh"></subAcDialog>
     </div>
</template>
<script>
import subAcDialog from './subAcDialog'
import { ref, getCurrentInstance, reactive, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus'
export default {
     components: {
          subAcDialog
     },
     setup() {
          const batchFlag = ref(false)
          const router = useRouter()
          const { proxy } = getCurrentInstance()
          const L = proxy.$getCurLanguage()
          const listParam = reactive({
               current: 1,
          })
          const accountSe = ref('')
          const subAcList = ref([])
          const acDelList = ref([])
          const selMeId = ref(null)
          const isReady = ref(false)
          const options = ref([
               {
                    label: L['启用'],
                    state: 1
               },
               {
                    label: L['禁用'],
                    state: 0
               },
               {
                    label: L['全部'],
                    state: -1
               }
          ])
          const ableState = ref(options.value[2].state)
          const batchDel = () => {
               if (!acDelList.value.length) {
                    ElMessage(L['请选择要删除的子账号'])
                    return
               }

               proxy.$confirm(L['确定删除该子账号？删除后账号不可继续使用。'], {
                    confirmButtonText: L['确定'],
                    cancelButtonText: L['取消'],
                    type: 'warning',
               }).then(() => {
                    proxy.$post('v3/member/front/enterprise/designer/delete', { memberIds: acDelList.value.join(',') }).then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              acDelList.value = []
                              getSubAcList()
                         } else {
                              ElMessage(res.msg)
                         }
                    })
               })
          }

          const getSubAcList = () => {
               isReady.value = false
               proxy.$get('v3/member/front/enterprise/designer/list', listParam).then(res => {
                    if (res.state == 200) {
                         isReady.value = true
                         subAcList.value = res.data.list
                    }
               })
          }

          const toCreate = () => {
               if (subAcList.value.length == 10) {
                    ElMessage(L['最多可创建10个子账号'])
                    return
               }
               router.push('/member/createSub')
          }

          const formatter = (row, column, cellValue, index) => {
               return cellValue ? cellValue : '--'
          }

          const handleSelectionChange = (selec) => {
               if (selec.length) {
                    acDelList.value = selec.map(item => item.memberId)
               } else {
                    acDelList.value = []
               }
          }

          const search = (type) => {
               if (type == 'go') {
                    listParam.account = accountSe.value
                    listParam.current = 1
               } else {
                    accountSe.value = ''
                    listParam.current = 1
                    delete listParam.account
               }
               getSubAcList()
          }

          const switchOp = (scope) => {
               let { state, memberId } = scope
               proxy.$post('v3/member/front/enterprise/designer/state', { state, memberId }).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                    } else {
                         ElMessage(res.msg)
                    }
               })
          }

          const view = (type, scoItem) => {
               selMeId.value = scoItem.memberId
               switch (type) {
                    case 'order': {
                         proxy.$refs.dialog.applyMember = selMeId.value ? selMeId.value : ''
                         proxy.$refs.dialog.memberId = selMeId.value
                         proxy.$refs.dialog.getSubOrder()
                         proxy.$refs.dialog.visiOrder = true
                         proxy.$refs.dialog.opState = 'ope'
                         break
                    }
                    case 'reset': {
                         proxy.$refs.dialog.visiReset = true
                         break
                    }
                    case 'edit': {
                         proxy.$refs.dialog.subAccountForm.phone = scoItem.memberMobile
                         proxy.$refs.dialog.subAccountForm.name = scoItem.designerName
                         proxy.$refs.dialog.visiEdit = true

                    }
               }
          }

          const delAcc = (memberId) => {
               acDelList.value = [memberId]
               batchDel()
          }

          const refresh = () => {
               getSubAcList()
          }

          const stateChange = (e) => {
               if (e == -1) {
                    delete listParam.state
               } else {
                    listParam.state = e
               }
               getSubAcList()
          }

          onMounted(() => {
               getSubAcList()
          })

          return {
               options,
               ableState,
               batchFlag,
               batchDel,
               toCreate,
               subAcList,
               formatter,
               handleSelectionChange,
               accountSe,
               search,
               switchOp,
               view,
               selMeId,
               refresh,
               delAcc,
               stateChange,
               isReady,
               L
          }
     },


}
</script>

<style lang="scss">
.subAccount {
     .el-dialog__header {
          display: none;
     }

     .el-dialog__body {
          padding: 0;
     }
}

.section22 {
     .el-input__inner {
          height: 33px;
          border-radius: 0;
     }

     .el-select .el-input.is-focus .el-input__inner {
          border-color: #dcdfe6;
     }
}
</style>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
     -webkit-appearance: none;
}

input::placeholder {
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: rgb(153, 153, 153);
}

::-webkit-input-placeholder {
     margin-left: 20px;
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: rgb(153, 153, 153);
}

/* 使用webkit内核的浏览器 */
:-moz-placeholder {
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: rgb(153, 153, 153);
}

/* Firefox版本19+ */
:-ms-input-placeholder {
     font-size: 12px;
     font-family: Microsoft YaHei;
     font-weight: 400;
     color: rgb(153, 153, 153);
}

/* IE浏览器 */
.subAccount {
     float: left;
     width: 1007px;
     background-color: #fff;
     margin-left: 15px;

     .title {
          height: 60px;
          border-bottom: 1px solid $colorI;
          line-height: 60px;
          padding-left: 20px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #666666;
     }

     .sub_acc_con {
          padding: 20px;

          .box3 {
               .word25 {
                    line-height: 32px;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 14px;
               }

               .main2 {
                    margin-left: 20px;

                    .el-select .el-input {
                         width: 150px;
                         height: 34px;

                    }
               }

               .section22 {
                    .info15 {
                         z-index: 190;
                         width: 28px;
                         height: 14px;
                         display: block;

                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular;
                         white-space: nowrap;
                         line-height: 14px;
                         text-align: left;
                    }

                    .label8 {
                         z-index: 187;
                         width: 14px;
                         height: 14px;
                    }
               }

               .main3 {
                    z-index: 194;
                    height: 32px;
                    border: 1px solid rgba(217, 217, 217, 1);
                    background-color: rgba(255, 255, 255, 1);
                    margin-left: 30px;
                    width: 255px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-left: 10px;

                    .bd8 {
                         height: 30px;
                    }

                    .info16 {
                         color: $colorTitle2;
                         font-size: 12px;
                         border: none;
                         outline: none;
                         width: 180px;
                    }

                    .box4 {
                         width: 40px;
                         height: 30px;
                         margin-left: 23px;
                         border-left: 1px solid #d9d9d9;
                         cursor: pointer;
                    }

                    .icon6 {
                         z-index: 196;
                         width: 16px;
                         height: 16px;
                         margin: 7px 0 0 9px;
                    }
               }

               .main4 {
                    height: 30px;
                    border-radius: 1px;
                    border: 1px solid rgba(217, 217, 217, 1);
                    background-color: rgba(255, 255, 255, 1);
                    width: 60px;
                    justify-content: center;
                    align-items: center;
                    margin: 1px 0 0 10px;

                    .word26 {

                         color: rgba(0, 0, 0, 0.65);
                         font-size: 14px;
                         cursor: pointer;
                    }
               }
          }

          .box5 {
               width: 190px;
               height: 30px;
               margin-top: 20px;
               justify-content: space-between;

               .bd9 {
                    height: 30px;
                    border-radius: 1px;
                    border: 1px solid rgba(217, 217, 217, 1);
                    width: 90px;
                    justify-content: center;
                    align-items: center;
                    background: #fff;
                    cursor: pointer;

                    .info17 {
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                    }
               }

               .bd10 {
                    cursor: pointer;
                    height: 30px;
                    border-radius: 1px;
                    background-color: rgba(40, 95, 222, 1);
                    width: 90px;
                    justify-content: center;
                    align-items: center;

                    .word27 {
                         color: rgba(255, 255, 255, 1);
                         font-size: 14px;
                    }
               }
          }

          .sub_table {
               margin-top: 20px;

               .options {
                    .txt17 {
                         display: inline-block;
                         padding: 0 5px;
                         border-left: 1px solid rgba(102, 102, 102, 1);
                         color: rgba(102, 102, 102, 1);
                         cursor: pointer;

                         &:first-child {
                              border-left: none;
                         }

                         &:hover {
                              color: $colorMain;
                         }
                    }
               }
          }
     }
}

.el-input__inner {
     height: 34px;
}
</style>